.paratitle p {
  font-size: 13px !important;
}
.fallBackGrid {
  text-align: right;
  padding-right: 10px;
}
.iconBtn {
  background-color: #f3f3ff;
  border-radius: 5px;
  padding: 5px 15px;
}
.socialIcon {
  position: relative;
  object-fit: cover;
}
.txtRight {
  text-align: right;
}
.mb10 {
  margin-bottom: 10px;
}
.dealWeekTitle {
  color: #cd4b0d;
  font-size: 18px;
  font-weight: 700;
}
.cityTitle {
  font-size: 18px;
  font-weight: 700;
  color: rgb(0, 86, 178);
}
.projectTitle {
  font-size: 16px !important;
  color: #171717;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 5px;
}
.cityName {
  font-size: 13px;
  color: rgb(23, 23, 23);
  font-weight: 500;
}
.priceTag h4 {
  font-size: 15px;
  color: rgb(23, 23, 23);
  font-weight: 700;
}
.mainLivability {
  margin-top: 68px;
  background-color: #fafafa;
}
.contentLivability {
  position: relative;
  margin-top: 0px !important;
}
.bgColor {
  background-color: #fafafa;
}
