@import '/styles/variables.module.scss';

.highlightWord {
  color: $darkorange-color; /* Assuming Variables.darkorangeColor is defined as a CSS variable */
  font-weight: 700;
  font-family: 'Open Sans' !important;
}

.capitals {
  text-transform: uppercase;
}

.developerCardGrid {
  height: 100%;
  max-height: 450px;
}
