@import '/styles/variables.module.scss';
.root {
  border-radius: 15px;
  display: block;
  margin-bottom: 2px !important;
  margin-top: 20px;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #d2deeb;
}

.root:hover {
  background-color: #ffffff;
  border: 1px solid rgba(53, 94, 150, 0.18);
}

.cardTop {
  position: relative;
}

.cardTopIcons {
  z-index: 2;
  position: absolute;
  bottom: 2px;
  left: 0px;
  padding: 7px 10px;
  margin-bottom: -15px;
}

.iconFilled {
  color: #ffffff !important;
  font-size: 15px !important;
}

.iconBorder {
  color: #ffffff;
}

.proBtn {
  margin: 5px 4px;
}

.proBtn1 {
  margin: 5px 4px;
}

.proBtn1 .MuiButton-label {
  color: #ffffff !important;
}

.proBtnContainer {
  display: flex;
  align-items: center;
}

.bhkValues {
  display: flex;
  flex-wrap: nowrap;
}

.customTooltip1 {
  background: #ffefe0 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  text-align: left;
  font: normal normal normal 12px/17px 'Open Sans';
  letter-spacing: 0px;
  color: $dark-color;
  opacity: 1;
  margin-top: 6px;
  width: 272px;
  height: auto;
  position: absolute;
  inset: auto 0 auto -32px;
}

.arrow {
  color: #ffefe0;
}

.customTooltip2 {
  background: #ffefe0 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  text-align: left;
  font: normal normal normal 12px/17px 'Open Sans';
  letter-spacing: 0px;
  color: $dark-color;
  opacity: 1;
  margin-top: 6px;
  width: 272px;
  height: auto;
  position: absolute;
  inset: auto 0 auto -32px;
}

.mobDealWeek {
  display: flex;
  align-items: center;
}

.bhkdata {
  color: $dark-color;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  font-weight: 500;
  font-size: 14px;
  padding-left: 4px;
}

.sqftData {
  color: $dark-color;
  font-weight: 500;
  font-size: 14px;

}

.locationData {
  color: $dark-color;
  font-weight: 500;
  font-size: 14px;
}
