// .root {}

.body {
  margin-top: 0px !important;
}
.projectImageGallery {
  .image-gallery-slides {
    img {
      min-height: 100vh;
    }
  }

  .image-gallery-icon {
    bottom: 30px !important;
  }
}

.tabRoot {
  &.MuiTab-root {
    min-width: fit-content !important;
    margin: 0 10px;
    text-transform: capitalize;
    padding-top: 10px;
  }
}
