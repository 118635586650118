@import '/styles/variables.module.scss';
.root {
  border: 1px solid #D2DEEB;
  padding-bottom: 0px;
  margin-right: 15px;
  box-shadow: none;
  border-radius: 7px;
}

.root .MuiCardContent-root {
  padding: 0;
}

.cardTop {
  position: relative;
}

.cardTop button {
  color: #fff !important;
  margin-top: -18px !important;
}

.cardTop button img {
  width: 22px !important;
  height: 22px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border-radius: 50% !important;
  padding: 4px;
}

.cardTop img {
  border-radius: 0px !important;
  margin-top: 5px;
}

.cardTopIcons {
  z-index: 2;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 6px 18px;
  display: flex;
  align-items: center;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 10%,
    rgba(0, 0, 0, 0%) 100%,
    rgba(0, 0, 0, 0) 23%,
    rgba(0, 0, 0, 0) 42%,
    rgba(0, 0, 0, 0) 100%,
    rgba(0, 0, 0, 0.6) 81%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
}

.cardTopDetails {
  z-index: 2;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.cardBody {
  padding: 10px 15px;
  position: relative;
  padding-bottom: 0px;
}

.cardDetails {
  width: 100%;
  min-height: 45px;
  display: flex;
  flex-wrap: wrap;
}

.media {
  height: 50px;
  padding-top: 56.25%; /* 16:9 */
}

.location {
  display: flex;
  align-items: center;
  margin-top: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.location h5 {
  font-size: 13px;
  color: rgb(111, 111, 111);
  font-weight: 400;
}

.cardIndex {
  font-size: 12px;
  border-radius: 15px;
  color: #EE6A2A;
  text-align: center;
  display: flex;
  margin-left: 6px;
  font-weight: bold;
}

.cardIndexMain {
  color: rgba(23, 23, 23, 0.8);
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
}

.cardIndexMain > div {
  display: flex;
  align-items: center;
}

.cardIndexMain > div > img {
  padding: 0 2px 0 0;
}

.iconBgColor {
  width: 31px;
  height: 28px;
  padding: 3px 0px 0px 0px;
  background-color: #17171755;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobDealWeek {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 7px;
  margin-bottom: 8px;
}

.flatInfo {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.flatsubhead h4 {
  font-size: 15px;
  color: $dark-color;
  font-weight: 700;
}

.flatsubhead h5 {
  font-size: 13px;
  color: $dark-color;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif !important;
  width: max-content;
  padding-left: 7px;

}

.phoneIcon {
  background: #0056B2;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  position: absolute;
  right: 15px;
  bottom: 5px;
}

.phoneIcon img {
  object-position: -437px 5px;
  object-fit: cover;
  width: 21px;
  height: 30px;
}

.iconFilled {
  color: #FFFFFF !important;
  margin-top: 5px !important;
  font-size: 16px !important;
}

.iconBorder {
  color: #FFFFFF;
}

.titleName h4 {
  font-size: 16px;
  color: $dark-color;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 5px;
}
