.borderRight {
  border-right: 1px solid #d2deeb;
  padding: 12px 9px;
}
.expinYears {
  font-weight: 500;
  text-align: center;
  font-size: 13px;
  color: #3b3f5c;
  white-space: nowrap;
}
.viwallText {
  display: flex;
  justify-content: center;
  background: rgb(0, 86, 178);
  align-items: center;
}
.cityTitle {
  font-size: 19px;
  font-weight: 700;
  color: rgb(0, 86, 178);
}

.cityName {
  font-size: 13px;
  color: rgb(23, 23, 23);
  font-weight: 500;
}
.textWhite {
  color: #fff !important;
}
.fwBold {
  font-weight: 700;
}
.borderLeft {
  border-left: 1px solid #fff;
  padding: 12px;
}
.rightIcon {
  margin-left: 5px;
  color: #fff !important;
  margin-top: 7px;
}
.devName {
  font-size: 15px;
  color: #cd4b0d;
  // color: #ee6a29;
  font-weight: 700;
  white-space: break-spaces;
  font-family: 'Open Sans', sans-serif !important;
  white-space: nowrap;
}
.dealWeekTitle {
  color: #cd4b0d;
  font-size: 19px;
  font-weight: 700;
}
.mainBuildersec {
  margin: 0 !important;
  margin-top: 20px !important;
  padding: 1rem 0rem 20px 1rem !important;
  background-color: #e5f0f8 !important;
}
.headerTopbuilder h2 {
  font-size: 19px;
}

/////////////
.cardFooter .MuiSvgIcon-root {
  color: #fff;
}

.cardFooter a {
  color: #fff;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  margin-right: 0px;
}

.cardFooter {
  padding: 0px !important;
}

.cardRoot {
  box-shadow: none;
  width: 100%;
  margin: auto;
}

.cardRoot .MuiCardContent-root:last-child {
  padding: 0px;
  background: none;
  color: #fff;
  font-weight: 700;
}

.cardRoot .MuiPaper-root {
  box-shadow: unset;
}

.cardSubHeader {
  color: black;
  height: 100%;
  max-height: 75px;
  padding: 0;
  border-right: none;
  border-left: none;
  border: 1px solid #d2deeb;
  padding: 0px !important;
}

.cardSubHeader .MuiGrid-grid-xs-6 {
  padding: 12px 0;
  border: 1px solid #d2deeb;
}

@media (max-width: 600px) {
  .cardSubHeader .MuiGrid-grid-xs-6 {
    border-top: 1px solid #d2deeb;
    border-bottom: 1px solid #d2deeb;
    border-left: none;
    border-right: none;
  }

  .cardSubHeader .MuiGrid-grid-xs-6:first-of-type {
    border-right: 1px solid #d2deeb;
  }
}

.cardInfo {
  padding: 17px;
  overflow: hidden;
}

.cardInfo .MuiTypography-root {
  color: black;
}

.cardInfo p {
  white-space: break-spaces;
}

.cardInfo h5 {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 500;
  font-size: 13px;
  color: #3b3f5c;
  line-height: 18px;
}

.cardRoot .MuiCardContent-root {
  padding: 0px !important;
}

.cardRoot .MuiCardContent-root:last-child {
  padding: 0 0 0px 0 !important;
}

.MuiCardContent-root.mobileBuilder_cardSubHeader {
  padding: 0px !important;
}

// .topBuilderView {
//   margin-top: 5px;
//   margin-bottom: 5px;
//   font-family: 'Open Sans', sans-serif;
// }

// .topBuilderView a {
//   margin-right: 1px;
// }
