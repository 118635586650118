@import '/styles/variables.module.scss';
.highlightWord {
  color: $primary-color;
  font-weight: 700;
}

.highlightWord1 {
  color: #2e72bb;
  font-weight: 700;
}

.mainSection {
  background-color: #dfeafb;
}

.cardRoot {
  box-shadow: none;
  width: 100%;
  margin: auto;
  border-radius: 0 0 4px 4px;
}

.cardRoot .MuiCardContent-root {
  padding: 0px;
}

.cardRoot .MuiCardContent-root:last-child {
  padding: 0 0 0px 0;
}

.cardFooter {
  padding: 0px !important;
}
.cardRoot .MuiPaper-root {
  box-shadow: unset;
}

.cardSubHeader {
  color: black;
  height: 100%;
  max-height: 75px;
  padding: 0px !important;
}

.cardSubHeader .MuiGrid-grid-xs-6 {
  padding: 12px 0 !important;
  border: 1px solid #ececec !important;
}

.cardSubHeader h4 {
  font-family: 'Open Sans' !important;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  color: #838c96;
}

.expText {
  font-weight: 700;
  font-size: 15px;
  color: black;
  font-family: 'Open Sans' !important;
  padding-right: 5px;
}

.cardInfo {
  padding: 18px;
  height: 110px;
  overflow: hidden;
}

.cardInfo .MuiTypography-root {
  color: black;
}

.cardInfo p {
  white-space: break-spaces;
}

.headingText h2 {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: bold;
  line-height: 1;
  font-size: 30px;
  color: $primary-color;
  padding-right: 18px;
  border-right: 3px solid #17171721;
  letter-spacing: -0.01562em !important;
}

.headingText p {
  letter-spacing: -0.01562em !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400;
  line-height: 1.8;
  font-size: 20px;
  color: var(--dark-color);
  padding-left: 18px;
  text-transform: uppercase;
}

.arrowBtn {
  margin-left: 5px;
  margin-top: 5px;
  color: #ffffff;
}
