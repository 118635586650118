@import '/styles/variables.module.scss';

.highlightWord {
  color: $darkorange-color;
  font-weight: bold;
  font-family: Open Sans, sans-serif !important;
}

.headingText {
  h2 {
    font-family: Open Sans, sans-serif !important;
    font-weight: bold;
    line-height: 1;
    font-size: 30px;
    color: $primary-color;
    padding-right: 18px;
    border-right: 3px solid #17171721;
    letter-spacing: -0.01562em !important;
  }

  p {
    font-family: Open Sans, sans-serif !important;
    font-weight: 400 !important;
    line-height: 1.8;
    font-size: 18px;
    color: $dark-color;
    padding-left: 18px;
    text-transform: uppercase;
  }
}

.newprojectname {
  h4 {
    color: #171717;
    font-size: 18px;
    font-weight: 700;
    font-family: Open Sans;
  }
}

.priceContent {
  color: $dark-color;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Open Sans';
}
