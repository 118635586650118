@import '/styles/variables.module.scss';
.headingNewProject h2 {
  font-family: Open Sans, sans-serif;
  font-weight: 700;
  line-height: 24px;
  font-size: 19px;
  margin-bottom: 2px;
  color: $primary-color;
}

.headingNewProject p {
  font-family: Open Sans, sans-serif;
  font-weight: 500;
  line-height: 18px;
  font-size: 13px;
  color: $dark-color;
  margin-bottom: 5px;
}
