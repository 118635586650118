@import '/styles/variables.module.scss';

.root {
  border: 1px solid #d2deeb;
  padding-bottom: 0px;
  margin-right: 15px;
  box-shadow: none !important;
  border-radius: 8px !important;
}

.root .MuiCardContent-root {
  padding: 0;
}

// .root .MuiCardContent-root img {
//   /* border-radius: 15px; */
// }

.cardTop {
  position: relative;
}

.cardTop button {
  color: #fff !important;
  margin-top: -18px !important;
}

.cardTop button img {
  width: 22px !important;
  height: 22px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border-radius: 50% !important;
  padding: 4px;
}

.cardTop img {
  border-radius: 7px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-top: 0px;
  margin-right: 0px;
}

.cardTopIcons {
  z-index: 2;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 6px 18px;
  display: flex;
  align-items: center;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 10%,
    rgba(0, 0, 0, 0) 100%,
    rgba(0, 0, 0, 0) 23%,
    rgba(0, 0, 0, 0) 42%,
    rgba(0, 0, 0, 0) 100%,
    rgba(0, 0, 0, 0.6) 81%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
}

.cardBody {
  padding: 10px 15px;
  position: relative;
  padding-bottom: 0px;
  min-height: 165px;
}

.mobDealWeek {
  display: inline;
  align-items: center;
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 7px;
  margin-bottom: 8px;
}

.flatInfo {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.flatInfo1 {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  margin-top: -2px;
}

.flatInfo1 h4 {
  font-size: 15px;
  color: $dark-color;
  font-weight: 700;
}

.flatInfo1 h5 {
  font-size: 13px;
  color: $dark-color;
  font-weight: 500;
  margin-left: 9px;
}

.flatsubhead h4 {
  font-size: 15px;
  color: $dark-color;
  font-weight: 700;
  padding-left: 10px;
}

.flatsubhead h5 {
  font-size: 13px;
  color: $dark-color;
  font-weight: 500;
  margin-left: 6px;
}

.phoneIcon {
  background: #0056b2;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  position: absolute;
  right: 15px;
  bottom: 5px;
}
.phoneIconData {
  object-position: -437px 5px;
  object-fit: cover;
  width: 21px;
  height: 30px;
}

.iconFilled {
  color: #ffffff !important;
  margin-top: 10px !important;
  font-size: 16px !important;
}

.iconBorder {
  color: #ffffff;
}
.cardDetails {
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-wrap: wrap;
  margin-top: -4px;
}
.titleData {
  margin-bottom: 7px !important;
}
.titleData h4 {
  font-size: 16px;
  color: $dark-color;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 5px;
}

.titleData h5 {
  font-size: 13px;
  color: $dark-color;
  font-weight: 500;
}
