.wrapper {
  box-sizing: border-box;
  display: flex;
  scroll-snap-type: both mandatory;
  overflow-x: scroll;
}

.wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.wrapper .previco-our-culture {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
}

.wrapper .nextico-our-culture {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}

.item {
  scroll-snap-align: start;
  padding-bottom: 0.5rem;
}

.positionRelative {
  position: relative;
}
